import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Prerequisites`}</h3>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`An account on the smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=ssh"
        }}>{`Register here`}</a></li>
      <li parentName="ul">{`An Azure Premium edition account (P1 or higher)`}</li>
      <li parentName="ul">{`Global administrator access to the account`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <p>{`The following provisioning features are supported:`}</p>
    <ul>
      <li parentName="ul">{`Push Groups and New Users`}</li>
      <li parentName="ul">{`Push Profile or Group Updates`}</li>
      <li parentName="ul">{`Push User Deactivation`}</li>
      <li parentName="ul">{`Reactivate Users`}</li>
    </ul>
    <h2>{`Overview`}</h2>
    <ol>
      <li parentName="ol">{`Create Groups in Azure Active Directory`}</li>
      <li parentName="ol">{`Tell us your directory's Tenant ID`}</li>
      <li parentName="ol">{`Add the Smallstep SSH Azure Enterprise Application to your tenant`}</li>
      <li parentName="ol">{`Enable user provisioning (SCIM) in Azure`}</li>
      <li parentName="ol">{`Check logs to confirm users and groups are syncing to Smallstep SSH`}</li>
    </ol>
    <h2>{`Step By Step Instructions`}</h2>
    <h3>{`Step 1. Create Groups`}</h3>
    <p>{`You'll grant access to your hosts via Active Directory Groups. If you don't already have groups set up, you'll want to create a group for each kind of user access to your servers. For example, you might have a group for `}<inlineCode parentName="p">{`ssh`}</inlineCode>{` users, and one for `}<inlineCode parentName="p">{`sudo`}</inlineCode>{` users.`}</p>
    <p>{`In the Azure portal, start at the `}<a parentName="p" {...{
        "href": "https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupsManagementMenuBlade/AllGroups"
      }}>{`Groups`}</a>{` blade.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/aad-create-user-group.png",
        "alt": null
      }}></img></p>
    <p>{`When creating your groups, give them names and accept the defaults on all other settings.`}</p>
    <h3>{`Step 2. Tell us your directory's Tenant ID`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the Smallstep SSH dashboard, under the Users tab, choose Azure.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Paste your Tenant ID from the `}<a parentName="p" {...{
            "href": "https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Overview"
          }}>{`Active Directory Overview`}</a>{` blade into the "Add Your Team" dialog:`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/azure-onboarding.png",
            "alt": null
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
      </li>
    </ol>
    <h3>{`Step 3. Add the Smallstep SSH Azure Enterprise Application`}</h3>
    <h4>{`Sign in to Smallstep SSH`}</h4>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Sign in to Smallstep at `}<inlineCode parentName="p">{`https://smallstep.com/app/[Team ID]`}</inlineCode></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Follow the Getting Started workflow.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose the `}<strong parentName="p">{`Users`}</strong>{` tab, and choose `}<strong parentName="p">{`Azure AD`}</strong>{` as your identity provider.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter your `}<strong parentName="p">{`Tenant ID`}</strong>{` and `}<strong parentName="p">{`Whitelisted Domains`}</strong>{`, and `}<strong parentName="p">{`Save`}</strong>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Now run `}<inlineCode parentName="p">{`step ssh login your@email`}</inlineCode>{`.
Your browser will open to an Azure AD single sign-on flow,
and you'll be prompted to add the Smallstep SSH enterprise application to your tenant.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/graphics/quickstart/azure-consent.png",
            "alt": "Azure consent screenshot"
          }}></img></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose `}<strong parentName="p">{`Consent on behalf of your organization.`}</strong></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Accept the application for your tenant, and finish the sign-on flow.`}</p>
      </li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`🤦‍♂️ If you encounter "The username may be incorrect", you'll need to use a different account to accept the application into your tenant.
Specifically, you cannot use a Microsoft Account or a Guest account; the account must be an Azure AD account.`}</p>
    </blockquote>
    <h4>{`Assign groups to your application`}</h4>
    <p>{`In the Azure Portal `}<a parentName="p" {...{
        "href": "https://portal.azure.com/#blade/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/AllApps/menuId/"
      }}>{`Enterprise Applications`}</a>{` blade, you should now see Smallstep SSH. Open it.`}</p>
    <p>{`Choose `}<strong parentName="p">{`Users and Groups`}</strong>{` on the left:`}</p>
    <ol>
      <li parentName="ol">{`Go to `}<strong parentName="li">{`+ Add User`}</strong>{` to create a new assignment.`}</li>
      <li parentName="ol">{`Select the groups you created in Step 1.`}</li>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Select`}</strong>{` on the bottom right.`}</li>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Assign`}</strong>{` on the bottom left.`}</li>
    </ol>
    <p>{`Your Users and Groups list should now look something like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/quickstart/aad-group-example.png",
        "alt": null
      }}></img></p>
    <h3>{`Step 4. Enable user provisioning (SCIM) in Azure`}</h3>
    <ol>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Provisioning`}</strong>{` on the left and choose `}<strong parentName="li">{`Get Started`}</strong>{`.`}</li>
      <li parentName="ol">{`Set the provisioning mode to `}<strong parentName="li">{`Automatic`}</strong>{`.`}</li>
      <li parentName="ol">{`Expand `}<strong parentName="li">{`Admin Credentials:`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`Supply the SCIM `}<strong parentName="li">{`Tenant URL`}</strong>{` and `}<strong parentName="li">{`Secret Token`}</strong>{` from the Smallstep dashboard.`}</li>
          <li parentName="ul">{`Choose `}<strong parentName="li">{`Test Connection`}</strong>{` and make sure that it works.`}</li>
          <li parentName="ul">{`Save.`}</li>
        </ul>
      </li>
    </ol>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Alternative `}<inlineCode parentName="strong">{`userName`}</inlineCode>{` Attribute Mappings`}</strong></p>
      <p parentName="blockquote">{`The `}<inlineCode parentName="p">{`userName`}</inlineCode>{` attribute determines the name of the POSIX account that will be created when users connect to a host.
By default, the expression returns the everything before the `}<inlineCode parentName="p">{`@`}</inlineCode>{` in the UPN, converted to lowercase:`}</p>
      <pre parentName="blockquote"><code parentName="pre" {...{}}>{`ToLower(Replace([userPrincipalName], , "(?<Suffix>@(.)*)", "Suffix", "", , ), )
`}</code></pre>
      <p parentName="blockquote">{`Here are some alternative UPN expressions that have been useful to customers:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">
          <p parentName="li">{`Remove dots `}<inlineCode parentName="p">{`.`}</inlineCode>{` from usernames`}</p>
          <p parentName="li">{`Some users have dotted UPNs, and POSIX usernames `}<em parentName="p">{`without`}</em>{` dots.
Linux usernames with `}<inlineCode parentName="p">{`.`}</inlineCode>{` in them are POSIX-compliant (`}<a parentName="p" {...{
              "href": "https://pubs.opengroup.org/onlinepubs/9699919799/basedefs/V1_chap03.html#tag_03_437"
            }}>{`IEEE Std 1003.1-2017, section 3.437`}</a>{`)
and in practice dotted usernames work fine on many systems.
If your UPNs contain dots,
you can configure the `}<inlineCode parentName="p">{`userName`}</inlineCode>{` attribute mapping to remove them.
Use the following expression for your `}<inlineCode parentName="p">{`userName`}</inlineCode>{` attribute:`}</p>
          <pre parentName="li"><code parentName="pre" {...{}}>{`ToLower(Replace(Replace([userPrincipalName], , "(?<Suffix>@(.)*)", "Suffix", "", , ), ".", , ,"", , ))
`}</code></pre>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Convert `}<inlineCode parentName="p">{`bill.gates@microsoft.com`}</inlineCode>{` to `}<inlineCode parentName="p">{`bgates`}</inlineCode>{`: `}</p>
          <pre parentName="li"><code parentName="pre" {...{}}>{`ToLower(Replace(Replace([userPrincipalName], , "(?<=^.{1}).\\w+.\\.", "", "", , ), , "(@(.)*)","","" , ,))
`}</code></pre>
        </li>
      </ul>
    </blockquote>
    <h5>{`Turn on Provisioning`}</h5>
    <ol>
      <li parentName="ol">{`Save your settings and return to the `}<strong parentName="li">{`Provisioning`}</strong>{` panel.`}</li>
      <li parentName="ol">{`Choose `}<strong parentName="li">{`Start Provisioning`}</strong>{`.`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`🤦‍♂️ There's a quirk in Microsoft's UI here, and you may see an error when saving after turning provisioning on. If so, wait 60 seconds and try Save again.`}</p>
    </blockquote>
    <h3>{`Step 5. Confirm the directory connection`}</h3>
    <p>{`Return to the Smallstep dashboard.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Navigate to the LOGS menu. You should see a list of success messages assocated with `}<inlineCode parentName="p">{`SCIM-SYNC`}</inlineCode>{` catagory items.`}</p>
        <p parentName="li">{` `}<img parentName="p" {...{
            "src": "/graphics/quickstart/scim-logs.png",
            "alt": null,
            "title": "SCIM Logs"
          }}></img></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Navigate to the USERS menu. If the onboarding dialog is open, press `}<inlineCode parentName="p">{`Esc`}</inlineCode>{` to close.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You should see your Users and Groups synced over from Azure AD.`}</p>
      </li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Don't see your users and groups?`}</strong>{` Microsoft's SCIM service may add a 40-minute delay after you set it up. You can force an update by clicking `}<strong parentName="p">{`Restart provisioning`}</strong>{` in the Provisioning panel. Even then, it may take a minute to sync with Smallstep.`}</p>
    </blockquote>
    <h3>{`Azure AD Configuration Complete`}</h3>
    <h2>{`Troubleshooting Tips`}</h2>
    <ul>
      <li parentName="ul">{`Initial activation of Azure AD OIDC provisioning in Smallstep SSH requires entering your `}<strong parentName="li">{`Application (client) ID`}</strong>{`, `}<strong parentName="li">{`Client secret`}</strong>{`, and `}<strong parentName="li">{`Configuration Endpoint`}</strong>{` into the Smallstep UI. Contact smallstep support with any questions | `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`support@smallstep.com`}</a></li>
      <li parentName="ul">{`Note: When users are deactivated in Azure AD, they will be deactivated in Smallstep. Users will not be able to SSH to servers, but their user accounts will remain on smallstep managed hosts. To permanently delete user data on smallstep managed hosts, contact Smallstep Support | `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`support@smallstep.com`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      